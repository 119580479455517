import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const FooterWrapper = styled.footer`
  margin-top: ${rhythm(2.5)};
  padding-top: ${rhythm(1)};
  a {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.textColor
        : LIGHT_THEME.textColor};
  }
`

function Footer() {
  return (
    <FooterWrapper>
      <div style={{ float: 'right' }}>
        <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
          rss
        </a>
      </div>
      <OutboundLink
        href="https://www.linkedin.com/in/thuannp"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </OutboundLink>
      &nbsp;&nbsp;
      <OutboundLink
        href="https://github.com/phuongthuan"
        target="_blank"
        rel="noopener noreferrer"
      >
        Github
      </OutboundLink>
      &nbsp;&nbsp;
      <OutboundLink
        href="https://stackoverflow.com/users/6570855/phuong-thuan"
        target="_blank"
        rel="noopener noreferrer"
      >
        StackOverFlow
      </OutboundLink>
    </FooterWrapper>
  )
}

export default Footer
