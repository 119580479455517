import React from 'react'
import { graphql } from 'gatsby'
import _get from 'lodash/get'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Post from '../components/Post'

class BlogPage extends React.Component {
  render() {
    const siteTitle = _get(this, 'props.data.site.siteMetadata.title')
    const posts = _get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Thuan Nguyen" />
        <aside>
          <Bio />
        </aside>
        <main>
          {posts.map(({ node }) => (
            <Post post={node} key={node.fields.slug} />
          ))}
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
