import React from 'react'
import { Link } from 'gatsby'
import _get from 'lodash/get'
import styled from 'styled-components'
import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const Wrapper = styled.article`
  display: flex;
  justify-content: space-between;
  header {
    margin-bottom: 1.5em;
    h3 {
      a {
        color: ${props =>
          props.theme && props.theme.color === 'dark'
            ? DARK_THEME.postTitleColor
            : LIGHT_THEME.postTitleColor};
      }
      margin: 0;
    }
  }
  footer {
    p {
      color: ${props =>
        props.theme && props.theme.color === 'dark'
          ? DARK_THEME.postDateColor
          : LIGHT_THEME.postDateColor};
      /* font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace; */
      /* text-transform: uppercase; */
      margin: 0;
    }
  }

  @media only screen and (max-width: 672px) {
    display: block;
    margin-bottom: 1.5em;
    header {
      margin-bottom: 0;
    }
  }
`

function Post({ post }) {
  const title = _get(post, 'frontmatter.title', 'fields.slug')
  return (
    <Wrapper>
      <header>
        <h3>
          <Link
            style={{
              boxShadow: `none`,
            }}
            to={post.fields.slug}
          >
            {title}
          </Link>
        </h3>
      </header>
      <footer>
        <p>{post.frontmatter.date}</p>
      </footer>
    </Wrapper>
  )
}

export default Post
